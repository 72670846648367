import axios from 'axios';


// Circuit Court, Combat, Municipal Court, Jackson County Crime Strategies Unit, ProX 
const defaultPartners= {
  id: 'partners',
  title: 'Partners',
  type: 'group',
  children: [
    {
      id: 'CircuitCourt',
      title: 'Circuit Court',
      type: 'item',
      url: '/partner/circuitCourt/upload',
    },
    {
      id: 'Combat',
      title: 'Combat',
      type: 'item',
      url: '/partner/combat/upload',
    },
    {
      id: 'MunicipalCourt',
      title: 'Municipal Court',
      type: 'item',
      url: '/partner/municipalCourt/upload',
    },
    {
      id: 'jacksonCrimeUnit',
      title: 'Crime Strategies Unit',
      type: 'item',
      url: '/partner/jacksonCrimeUnit/upload',
    },
    {
      id: 'ProX',
      title: 'ProX',
      type: 'item',
      url: '/partner/prox/upload',
    },
  ]
};

const isTokenAvailable = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken !== null;
};

const fetchData = async () => {
  try {
    if (!isTokenAvailable()) {
      // console.log('Token not available, returning default programs.');
      return defaultPartners.children;
    }

    const jwtToken = localStorage.getItem('accessToken');
    const host = process.env.REACT_APP_API_HOST || 'localhost';
    const port = process.env.REACT_APP_API_PORT || '4747';
    const protocol = process.env.REACT_APP_API_PROTOCOL|| 'http';
    const response = await axios.get(`${protocol}://${host}:${port}/api/get_users_programs`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken ? `Bearer ${jwtToken}` : '',
      },
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    // console.error('Error fetching user programs:', error);
    return defaultPartners.children;
  }
};

const apiResponse = await fetchData();

// Filter and update the programs only if the token is available
let updatedPrograms = defaultPartners.children;
if (isTokenAvailable()) {
  updatedPrograms = defaultPartners.children.filter(item => {
    const isMatch = apiResponse.some(apiProgram => {
      if (apiProgram.partner === true) {
        const apiProgramName = apiProgram.program.toLowerCase();
        const itemTitle = item.id.toLowerCase();
        return apiProgramName === itemTitle;
      }
      return false;
    });
    return isMatch;
  });
}

// console.log(updatedPrograms);

// Update the programs object with the dynamically updated programs
const partners = {
  ...defaultPartners,
  children: updatedPrograms,
};

export default partners;
