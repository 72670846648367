import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Protected = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      if (!token) {
        setIsTokenValid(false);
        return;
      }

      const host = process.env.REACT_APP_API_HOST || 'localhost';
      const port = process.env.REACT_APP_API_PORT || '4747';
      const protocol = process.env.REACT_APP_API_PROTOCOL || 'http';

      try {
        const response = await fetch(`${protocol}://${host}:${port}/api/auth/validate_token`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ program: location.pathname })
        });
        if (response.ok) {
          setIsTokenValid(true);
        } else {
          if (response['status'] === 404) {
            //setIsTokenValid(false);
            navigate('/');
          } else if (localStorage.getItem('accessToken') !== null) {
            localStorage.removeItem('accessToken');
            setIsTokenValid(false);
          }
        }
      } catch (error) {
        console.error('Failed to validate token:', error);
      }
    };

    if (isTokenValid === null) {
      validateToken();
    } else if (isTokenValid === false) {
      navigate('/pages/login/login3?redirect=' + location.pathname);
    }
  }, [token, isTokenValid, navigate, location.pathname]);

  if (isTokenValid === null) {
    return null;
  }

  return <>{children}</>;
};

export default Protected;
