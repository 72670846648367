import React from 'react';
const ErrorPage = ({ error }) => {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <h1>An Error Occurred, Its not you, its us!</h1>
      <p>{error?.message || 'Something went wrong, please try again later.'}</p>
      <button onClick={() => window.location.href = '/'} style={{ margin: '1rem' }}>Go to Home</button>
      <button onClick={() => window.location.reload()} style={{ margin: '1rem' }}>Reload</button>
    </div>
  );
};

export default ErrorPage;
