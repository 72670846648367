import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

const DashboardCard = React.memo(function DashboardCard({ item }) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        textAlign: 'center',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: isMobile ? 'none' : 'translateY(-10px)',
          boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)'
        },
        boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '16px',
        background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
        maxWidth: 350,
        margin: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={3}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
            width: 60,
            height: 60,
            margin: 'auto',
            borderRadius: '50%',
            backgroundColor: `${item.color}20`
          }}
        >
          {React.cloneElement(item.icon, { style: { color: item.color } })}
        </Box>
        <Typography
          variant="h6"
          sx={{
            mt: 3,
            fontWeight: 'bold',
            color: 'text.primary',
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem', lg: '1.4rem' }
          }}
        >
          {item.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1, mt: 3 }}>
          {item.description}
        </Typography>
      </CardContent>
      <Box sx={{ p: 2 }}>
        <Button
          disabled={item.comingSoon}
          variant="contained"
          fullWidth
          onClick={() => navigate(item.path)}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 'bold',
            background: item.comingSoon ? '#ccc' : `linear-gradient(45deg, ${item.color}, ${item.color}CC)`,
            transition: 'background 0.3s, box-shadow 0.3s',
            boxShadow: item.comingSoon ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              background: item.comingSoon ? '#ccc' : `linear-gradient(45deg, ${item.color}CC, ${item.color}FF)`,
              boxShadow: item.comingSoon ? 'none' : '0 6px 14px rgba(0, 0, 0, 0.2)'
            }
          }}
          aria-label={item.buttonText}
        >
          {item.comingSoon ? 'Coming Soon' : item.buttonText}
        </Button>
      </Box>
    </Card>
  );
});

DashboardCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    buttonText: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    comingSoon: PropTypes.bool.isRequired
  }).isRequired
};

export default DashboardCard;
