import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Box, CircularProgress, Fade } from '@mui/material';
import AlertDialog from 'views/dashboard/components/AlertDialog';
import { usePermission, useUser } from 'context/UserContext';
import { ACCESSES } from 'utils/permissions';
import { dashboardItems } from 'views/dashboard/data/dashboardItems';
import DashboardCard from 'views/dashboard/components/DashboardCard';

export default function HomeDashboard() {
  const { loading: userLoading } = useUser();
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);
  const { loading: accessLoading, hasPermission: hasDashboardAccess, details: accessibleResources } = usePermission(ACCESSES.DASHBOARD);

  useEffect(() => {
    setChecked(true);
  }, []);

  const authorizedItems = useMemo(() => {
    if (hasDashboardAccess && accessibleResources) {
      return dashboardItems.filter((item) => accessibleResources[item.resource]);
    }
    return [];
  }, [hasDashboardAccess, accessibleResources]);

  const showErrorDialog = useMemo(() => {
    return hasDashboardAccess && authorizedItems.length === 0;
  }, [hasDashboardAccess, authorizedItems.length]);

  useEffect(() => {
    if (showErrorDialog) {
      setError('You do not have access to any dashboard items. Contact the admin to request access.');
    } else {
      setError('');
    }
  }, [showErrorDialog]);

  const handleCloseErrorDialog = () => {
    setError('');
  };

  if (userLoading || accessLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fade in={checked} timeout={500}>
      <Box sx={{ padding: { xs: 2, sm: 4 }, maxWidth: '1200px', margin: 'auto' }}>
        {error && <AlertDialog open={Boolean(error)} onClose={handleCloseErrorDialog} title="No Access" message={error} />}

        {authorizedItems.length > 0 && (
          <Grid container spacing={{ xs: 2, sm: 3 }} justifyContent="center">
            {authorizedItems.map((item, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                <DashboardCard item={item} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Fade>
  );
}
