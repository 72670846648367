import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import NotFound from 'views/pages/errors/NotFound';
import { usePermission } from 'context/UserContext';

const DashboardProtectedRoute = ({ children, access, resource = null, action = null }) => {
  const { loading, hasPermission } = usePermission(access, resource, action);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!hasPermission) {
    return <NotFound />;
  }

  return children;
};

export const generateDashboardProtectedRoute = (component, access, resource = null, action = null) => {
  return (
    <DashboardProtectedRoute access={access} resource={resource} action={action}>
      {component}
    </DashboardProtectedRoute>
  );
};
