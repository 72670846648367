export const ACCESSES = {
  DASHBOARD: 'dashboard',
  // Add as needed
}

export const RESOURCES = {
  FILES: 'files',
  USERS: 'users',
  PARTICIPANT_IN_PROGRAM_DATA : "participant_in_program_data",
  EVENT_DATA : "event_data",
  CITIZEN_DATA : "citizen_data",
  // Add as needed
};

export const ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  DOWNLOAD: 'download',
  VIEW: 'view',
  PASSWORD_UPDATE: 'password_update',
  PROFILE_UPDATE: 'profile_update',
  TYPE_UPDATE: 'type_update',
  STATUS_UPDATE: 'status_update',
  USER_PROGRAMS_UPDATE: 'user_programs_update',
  READ_FILES: 'read_files',
  READ_PROGRAMS: 'read_programs',
  READ_INSIGHTS: 'read_insights',
  READ_USERS: 'read_users',

  // Add as needed
};

// There must be insync with the backend permissions and their respective resources

export default { ACTIONS , RESOURCES, ACCESSES };
