import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const DashboardBackgroundStyle = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  marginBottom: '8px',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 3,
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '60px',
    height: '60px',
    background: theme.palette.primary[200],
    borderRadius: '50%',
    top: '-20px',
    right: '-20px'
  }
}));

export default DashboardBackgroundStyle;
