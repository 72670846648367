import { lazy, React } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import HomeContent from 'views/home/HomeContent';
import HomeDashboard from 'views/dashboard/home/HomeDashboard';
import { ACCESSES, ACTIONS, RESOURCES } from 'utils/permissions';
import { generateDashboardProtectedRoute } from 'routes/DashboardProtectedRouteManager';

// dashboard routing
const DashboardFiles = Loadable(lazy(() => import('views/dashboard/dashboard-items/FilesItem')));
const DashboardParticipantInProgramData = Loadable(lazy(() => import('views/dashboard/dashboard-items/ParticipantInProgramDataItem')));
const DashboardEventData = Loadable(lazy(() => import('views/dashboard/dashboard-items/EventDataItems')));
const DashboardCitizenData = Loadable(lazy(() => import('views/dashboard/dashboard-items/CitizenDataItem')));
const DashboardUsers = Loadable(lazy(() => import('views/dashboard/dashboard-items/UsersItem')));
const DashboardUserManage = Loadable(lazy(() => import('views/dashboard/components/UserManage')));
const DashboardCreateUser = Loadable(lazy(() => import('views/dashboard/components/CreateUser')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HomeContent />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: generateDashboardProtectedRoute(<HomeDashboard />, ACCESSES.DASHBOARD)
        },
        {
          path: 'files',
          element: generateDashboardProtectedRoute(<DashboardFiles />, ACCESSES.DASHBOARD, RESOURCES.FILES)
        },
        {
          path: 'dashboard/participant-in-program-surveys',
          element: generateDashboardProtectedRoute(
            <DashboardParticipantInProgramData />,
            ACCESSES.DASHBOARD,
            RESOURCES.PARTICIPANT_IN_PROGRAM_DATA
          )
        },
        {
          path: 'event-surveys',
          element: generateDashboardProtectedRoute(<DashboardEventData />, ACCESSES.DASHBOARD, RESOURCES.EVENT_DATA)
        },
        {
          path: 'citizen-surveys',
          element: generateDashboardProtectedRoute(<DashboardCitizenData />, ACCESSES.DASHBOARD, RESOURCES.CITIZEN_DATA)
        },
        {
          path: 'users',
          children: [
            {
              path: '',
              element: generateDashboardProtectedRoute(<DashboardUsers />, ACCESSES.DASHBOARD, RESOURCES.USERS)
            },
            {
              path: 'manage/:username',
              element: generateDashboardProtectedRoute(<DashboardUserManage />, ACCESSES.DASHBOARD, RESOURCES.USERS)
            },
            {
              path: 'create',
              element: generateDashboardProtectedRoute(<DashboardCreateUser />, ACCESSES.DASHBOARD, RESOURCES.USERS, ACTIONS.CREATE)
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
