import { useState, useEffect } from 'react';
import { Typography, Grow } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { usePermission } from 'context/UserContext';
import { ACCESSES } from 'utils/permissions';
import dashboard from 'menu-items/dashboard';
import NavItem from './NavItem';
import DashboardBackgroundStyle from '../MenuCard/DashboardBackgroundStyle';

// ==============================|| SIDEBAR MENU LIST ||============================== //

export const MenuList = () => {
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export const Dashboard = () => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  const { loading: accessLoading, hasPermission: hasDashboardPermission } = usePermission(ACCESSES.DASHBOARD);

  if (accessLoading || !hasDashboardPermission || !dashboard) {
    return null;
  }

  return (
    <Grow in={checked} timeout={500}>
      <DashboardBackgroundStyle>
        <NavItem key={dashboard.id} item={dashboard} />
      </DashboardBackgroundStyle>
    </Grow>
  );
};
