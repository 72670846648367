// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const isTokenAvailable = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken !== null;
};

const dashboardConfig = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'item',
  url: '/dashboard',
  icon: icons.IconDashboard,
  breadcrumbs: false
};

const dashboard = isTokenAvailable() ? dashboardConfig : null;

export default dashboard;
