import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchCurrentUser } from './api';
import AlertDialog from 'views/dashboard/components/AlertDialog';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alertDialog, setAlertDialog] = useState({
    open: false,
    message: '',
    type: 'error',
    onCloseCallback: null
  });

  const accessToken = localStorage.getItem('accessToken');

  const fetchUser = async () => {
    try {
      const response = await fetchCurrentUser();
      setUser(response.data);
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        setUser(null);
        localStorage.clear();
        setAlertDialog({
          open: true,
          message:
            'You are deactivated or unauthorized to access this system. Please contact the system administrator to reactivate your account.',
          type: 'error',
          onCloseCallback: () => {
            window.location.href = '/';
          }
        });
      } else {
        setAlertDialog({
          open: true,
          message:
            'There was an issue fetching your user data. Some functionalities may be limited. Please report this to the system administrator if the issue persists.',
          type: 'error'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      setUser(null);
      setLoading(false);
    } else {
      fetchUser();
    }
  }, [accessToken]);

  const handleCloseAlert = () => {
    setAlertDialog((prev) => ({ ...prev, open: false }));
    if (alertDialog.onCloseCallback) {
      alertDialog.onCloseCallback();
    }
  };
  
  return (
    <UserContext.Provider value={{ user, loading, reloadUser: fetchUser }}>
      {children}
      <AlertDialog open={alertDialog.open} message={alertDialog.message} onClose={handleCloseAlert} title="Error" />
    </UserContext.Provider>
  );
};

export const usePermission = (access, resource = null, action = null) => {
  const { user, loading } = useUser();

  if (loading) return { loading: true, hasPermission: false, details: [] };

  if (!user) return { loading: false, hasPermission: false, details: [] };

  var hasPermission = false;
  var details = null;

  if (access && resource && action) {
    // Specific action permission
    hasPermission = user.accesses?.[access]?.[resource]?.actions?.includes(action) || false;
    details = user.accesses?.[access]?.[resource]?.actions || [];
  } else if (access && resource) {
    // Resource-level permission
    hasPermission = Boolean(user.accesses?.[access]?.[resource]?.actions?.length);
    details = user.accesses?.[access]?.[resource]?.actions || [];
  } else if (access) {
    // Access-level permission
    hasPermission = Boolean(user.accesses?.[access]);
    details = user.accesses?.[access] || {};
  }

  return { loading: false, hasPermission, details: details };
};