import React from 'react';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { RESOURCES } from 'utils/permissions';

export const dashboardItems = [
  {
    title: 'Files',
    description: 'Manage and Download the files uploaded by programs.',
    icon: <DescriptionOutlinedIcon fontSize="large" />,
    label: 'Files',
    buttonText: 'Go to Files ->',
    path: '/dashboard/files',
    color: '#f57c00',
    resource: RESOURCES.FILES,
    comingSoon: false
  },
  {
    title: 'Participant in Program Surveys',
    description: 'Download and analyze the participant in programs surveys of eligible programs.',
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    label: 'Surveys',
    buttonText: 'Go to Participant in Program Surveys ->',
    path: '/dashboard/dashboard/participant-in-program-surveys',
    color: '#2e7d32',
    resource: RESOURCES.PARTICIPANT_IN_PROGRAM_DATA,
    comingSoon: false
  },
  {
    title: 'Event Surveys',
    description: 'Download and analyze the events surveys of eligible programs.',
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    label: 'Surveys',
    buttonText: 'Go to Event Surveys ->',
    path: '/dashboard/event-surveys',
    color: '#303f9f',
    resource: RESOURCES.EVENT_DATA,
    comingSoon: false
  },
  {
    title: 'Citizen Surveys',
    description: 'Download and analyze the citizens surveys of eligible programs.',
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    label: 'Surveys',
    buttonText: 'Go to Citizen Surveys ->',
    path: '/dashboard/citizen-surveys',
    color: '#d32f2f',
    resource: RESOURCES.CITIZEN_DATA,
    comingSoon: false
  },
  {
    title: 'Users',
    description: 'Manage all users, update profiles, and control program access.',
    icon: <PeopleAltOutlinedIcon fontSize="large" />,
    label: 'Users',
    buttonText: 'Go to Users ->',
    path: '/dashboard/users',
    color: '#1976d2',
    resource: RESOURCES.USERS,
    comingSoon: false
  }
];
